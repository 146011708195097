import React from "react"
import { Page, PageCenter, DefaultHeader } from "../../components/elements"

export default function Main() {
    if (typeof window !== "undefined") {
        var queryString = window.location.search
        var urlParams = new URLSearchParams(queryString)
    }
    const id = urlParams?.get("id")
    const url = `https://authenticate.egi.utah.edu/download/projects/files/companyDownloads/file/${id}.zip`

    return (
        <Page>
            <PageCenter>
                <DefaultHeader
                    title="ArcGIS Report Access"
                    subtitle="Follow the steps below to access desired report"
                />
                <div>
                    <ol>
                        <li>
                            Login to EGIconnect:{" "}
                            <a
                                href="https://authenticate.egi.utah.edu/download/customer/login?service=https%3A%2F%2Fauthenticate.egi.utah.edu%2Fdownload%2Fcustomer%2Flogin"
                                target="new"
                            >
                                click to login
                            </a>
                        </li>
                        <li>
                            Download desired report here:{" "}
                            <a href={url} target="new">
                                download link
                            </a>
                        </li>
                    </ol>
                </div>
            </PageCenter>
        </Page>
    )
}
